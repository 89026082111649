
.mainContainer {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100vw;
    height: 100vh;
    background-color: darkslategray;
    color: wheat;

    font-size: 3rem;

    > * {
        margin-bottom: 1vh;
    }

    > *:nth-child(1) {
        margin-top: 10vh;
    }
}